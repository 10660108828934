// ! No CSSComb here (breaks the layout)

.footer {
  background-color: $brand-321;
  color: $white;
  overflow: hidden;
  margin-top: auto;

  @include gridle_state (large xlarge) {
    // to avoid menu breaks
    font-size: 15px;
  }

  &__row {
    @include gridle_row();
  }

  &__content {
    margin-top: $page-margin;
    margin-bottom: $page-margin;
    @extend .content;

    @include gridle_state (small) {
      margin-top: $page-margin-sm - 5px;
      margin-bottom: $page-margin-sm - 5px;
    }

    @include gridle_state (medium) {
      margin-top: $page-margin-sm;
      margin-bottom: $page-margin-sm;
    }

    &__left {
      text-align: left;
      @include gridle_grid(12);
      width: 30%;
    }
    &__center {
      /* text-align: center; */

      margin-left:10px;

      @include gridle_grid(12);
      width: 65%;
      padding-right: 0px;
      padding-left: 0px;
    }
    &__right {
      text-align: right;
      @include gridle_grid(12);
      width: 30%;
    }
    @include gridle_state (small) {
      // --- Mit Nav content ---
      // &__center {
      //   @include gridle_grid(6);
      //   padding-right: $content-margin-sm;
      //   float: right;
      //   text-align: right;
      // }
      // &__left,
      // &__right {
      //   @include gridle_grid(6);
      //   float: left;
      //   text-align: left;
      // }

      // --- Ohne Nav content ---
      &__left,
      &__right {
        @include gridle_grid(12);
        float: left;
        text-align: center;
      }
    }
    @include gridle_state (medium) {
      &__left {
        width: 50%;
      }
      &__center {
        display: none;
      }
      &__right {
        width: 50%;
      }
    }
  }

  &__menu {
    margin: 0 -8px; // item padding ausgleichen
    &__item {
      padding: 0 8px;
    }
    @include gridle_state (small medium) {
      &-seperator { // function as br on tablet
        display: block;
        height: 0;
        width: 0;
        visibility: hidden;
      }
    }
  }
}