.badge {
  position: absolute;

  max-width: 35%;
  height: 0;
  margin-left: $content-margin;
  &__image {
    display: block;

    position: relative;

    max-width: 100%;

    transform: translateY(-50%);
    &.circle {
      border-radius: 500px;
    }
  }
}