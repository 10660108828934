$fonts-folder-path: '../fonts/';

@font-face {
    font-family: 'Source Sans Pro';
    src: url($fonts-folder-path + 'sourcesanspro-regular-webfont.eot');
    src: url($fonts-folder-path + 'sourcesanspro-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url($fonts-folder-path + 'sourcesanspro-regular-webfont.woff2') format('woff2'),
         url($fonts-folder-path + 'sourcesanspro-regular-webfont.woff') format('woff'),
         url($fonts-folder-path + 'sourcesanspro-regular-webfont.ttf') format('truetype'),
         url($fonts-folder-path + 'sourcesanspro-regular-webfont.svg#source_sans_proregular') format('svg');
    font-weight: 400;
    font-style: normal;
}


// @font-face {
//     font-family: 'Source Sans Pro';
//     src: url($fonts-folder-path + 'sourcesanspro-it-webfont.eot');
//     src: url($fonts-folder-path + 'sourcesanspro-it-webfont.eot?#iefix') format('embedded-opentype'),
//          url($fonts-folder-path + 'sourcesanspro-it-webfont.woff2') format('woff2'),
//          url($fonts-folder-path + 'sourcesanspro-it-webfont.woff') format('woff'),
//          url($fonts-folder-path + 'sourcesanspro-it-webfont.ttf') format('truetype'),
//          url($fonts-folder-path + 'sourcesanspro-it-webfont.svg#source_sans_proitalic') format('svg');
//     font-weight: 400;
//     font-style: italic;
// }


// @font-face {
//     font-family: 'Source Sans Pro';
//     src: url($fonts-folder-path + 'sourcesanspro-extralightit-webfont.eot');
//     src: url($fonts-folder-path + 'sourcesanspro-extralightit-webfont.eot?#iefix') format('embedded-opentype'),
//          url($fonts-folder-path + 'sourcesanspro-extralightit-webfont.woff2') format('woff2'),
//          url($fonts-folder-path + 'sourcesanspro-extralightit-webfont.woff') format('woff'),
//          url($fonts-folder-path + 'sourcesanspro-extralightit-webfont.ttf') format('truetype'),
//          url($fonts-folder-path + 'sourcesanspro-extralightit-webfont.svg#source_sans_proXLtIt') format('svg');
//     font-weight: 200;
//     font-style: italic;
// }


// @font-face {
//     font-family: 'Source Sans Pro';
//     src: url($fonts-folder-path + 'sourcesanspro-light-webfont.eot');
//     src: url($fonts-folder-path + 'sourcesanspro-light-webfont.eot?#iefix') format('embedded-opentype'),
//          url($fonts-folder-path + 'sourcesanspro-light-webfont.woff2') format('woff2'),
//          url($fonts-folder-path + 'sourcesanspro-light-webfont.woff') format('woff'),
//          url($fonts-folder-path + 'sourcesanspro-light-webfont.ttf') format('truetype'),
//          url($fonts-folder-path + 'sourcesanspro-light-webfont.svg#source_sans_prolight') format('svg');
//     font-weight: 300;
//     font-style: normal;
// }


// @font-face {
//     font-family: 'Source Sans Pro';
//     src: url($fonts-folder-path + 'sourcesanspro-blackit-webfont.eot');
//     src: url($fonts-folder-path + 'sourcesanspro-blackit-webfont.eot?#iefix') format('embedded-opentype'),
//          url($fonts-folder-path + 'sourcesanspro-blackit-webfont.woff2') format('woff2'),
//          url($fonts-folder-path + 'sourcesanspro-blackit-webfont.woff') format('woff'),
//          url($fonts-folder-path + 'sourcesanspro-blackit-webfont.ttf') format('truetype'),
//          url($fonts-folder-path + 'sourcesanspro-blackit-webfont.svg#source_sans_problack_italic') format('svg');
//     font-weight: 900;
//     font-style: italic;
// }


// @font-face {
//     font-family: 'Source Sans Pro';
//     src: url($fonts-folder-path + 'sourcesanspro-extralight-webfont.eot');
//     src: url($fonts-folder-path + 'sourcesanspro-extralight-webfont.eot?#iefix') format('embedded-opentype'),
//          url($fonts-folder-path + 'sourcesanspro-extralight-webfont.woff2') format('woff2'),
//          url($fonts-folder-path + 'sourcesanspro-extralight-webfont.woff') format('woff'),
//          url($fonts-folder-path + 'sourcesanspro-extralight-webfont.ttf') format('truetype'),
//          url($fonts-folder-path + 'sourcesanspro-extralight-webfont.svg#source_sans_proextralight') format('svg');
//     font-weight: 200;
//     font-style: normal;
// }


// @font-face {
//     font-family: 'Source Sans Pro';
//     src: url($fonts-folder-path + 'sourcesanspro-lightit-webfont.eot');
//     src: url($fonts-folder-path + 'sourcesanspro-lightit-webfont.eot?#iefix') format('embedded-opentype'),
//          url($fonts-folder-path + 'sourcesanspro-lightit-webfont.woff2') format('woff2'),
//          url($fonts-folder-path + 'sourcesanspro-lightit-webfont.woff') format('woff'),
//          url($fonts-folder-path + 'sourcesanspro-lightit-webfont.ttf') format('truetype'),
//          url($fonts-folder-path + 'sourcesanspro-lightit-webfont.svg#source_sans_prolight_italic') format('svg');
//     font-weight: 300;
//     font-style: italic;
// }


@font-face {
    font-family: 'Source Sans Pro';
    src: url($fonts-folder-path + 'sourcesanspro-bold-webfont.eot');
    src: url($fonts-folder-path + 'sourcesanspro-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url($fonts-folder-path + 'sourcesanspro-bold-webfont.woff2') format('woff2'),
         url($fonts-folder-path + 'sourcesanspro-bold-webfont.woff') format('woff'),
         url($fonts-folder-path + 'sourcesanspro-bold-webfont.ttf') format('truetype'),
         url($fonts-folder-path + 'sourcesanspro-bold-webfont.svg#source_sans_probold') format('svg');
    font-weight: 700;
    font-style: normal;
}


// @font-face {
//     font-family: 'Source Sans Pro';
//     src: url($fonts-folder-path + 'sourcesanspro-boldit-webfont.eot');
//     src: url($fonts-folder-path + 'sourcesanspro-boldit-webfont.eot?#iefix') format('embedded-opentype'),
//          url($fonts-folder-path + 'sourcesanspro-boldit-webfont.woff2') format('woff2'),
//          url($fonts-folder-path + 'sourcesanspro-boldit-webfont.woff') format('woff'),
//          url($fonts-folder-path + 'sourcesanspro-boldit-webfont.ttf') format('truetype'),
//          url($fonts-folder-path + 'sourcesanspro-boldit-webfont.svg#source_sans_probold_italic') format('svg');
//     font-weight: 700;
//     font-style: italic;
// }


// @font-face {
//     font-family: 'Source Sans Pro';
//     src: url($fonts-folder-path + 'sourcesanspro-black-webfont.eot');
//     src: url($fonts-folder-path + 'sourcesanspro-black-webfont.eot?#iefix') format('embedded-opentype'),
//          url($fonts-folder-path + 'sourcesanspro-black-webfont.woff2') format('woff2'),
//          url($fonts-folder-path + 'sourcesanspro-black-webfont.woff') format('woff'),
//          url($fonts-folder-path + 'sourcesanspro-black-webfont.ttf') format('truetype'),
//          url($fonts-folder-path + 'sourcesanspro-black-webfont.svg#source_sans_problack') format('svg');
//     font-weight: 900;
//     font-style: normal;
// }


// @font-face {
//     font-family: 'Source Sans Pro';
//     src: url($fonts-folder-path + 'sourcesanspro-semiboldit-webfont.eot');
//     src: url($fonts-folder-path + 'sourcesanspro-semiboldit-webfont.eot?#iefix') format('embedded-opentype'),
//          url($fonts-folder-path + 'sourcesanspro-semiboldit-webfont.woff2') format('woff2'),
//          url($fonts-folder-path + 'sourcesanspro-semiboldit-webfont.woff') format('woff'),
//          url($fonts-folder-path + 'sourcesanspro-semiboldit-webfont.ttf') format('truetype'),
//          url($fonts-folder-path + 'sourcesanspro-semiboldit-webfont.svg#source_sans_proSBdIt') format('svg');
//     font-weight: 600;
//     font-style: italic;
// }


@font-face {
    font-family: 'Source Sans Pro';
    src: url($fonts-folder-path + 'sourcesanspro-semibold-webfont.eot');
    src: url($fonts-folder-path + 'sourcesanspro-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url($fonts-folder-path + 'sourcesanspro-semibold-webfont.woff2') format('woff2'),
         url($fonts-folder-path + 'sourcesanspro-semibold-webfont.woff') format('woff'),
         url($fonts-folder-path + 'sourcesanspro-semibold-webfont.ttf') format('truetype'),
         url($fonts-folder-path + 'sourcesanspro-semibold-webfont.svg#source_sans_prosemibold') format('svg');
    font-weight: 600;
    font-style: normal;
}