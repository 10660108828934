*:focus {
  outline: none;
}

img {
  max-width: 100%;
}

.content,
.main-content {
  width: 100%;
  max-width: $page-max-width;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $content-margin;
  @media (max-width: $page-max-width-with-margin) {
    max-width: $page-max-width-with-margin;
    padding-right: $content-margin-sm;
    margin-bottom: $content-margin-sm;
    padding-left: $content-margin-sm;
  }
}

// Inside of e.g. the main-content for blocks of content
.content-block {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: $content-margin;
  @media (max-width: $page-max-width-with-margin) {
    margin-bottom: $content-margin-sm;
  }
}

.main-content {
  overflow: hidden;

  margin-top: $content-margin;
  margin-bottom: 0; // to prevent double margin from other contents
}

@mixin column-margin() {
  margin-bottom: $grid-padding;
}
@mixin column-anti-margin() {
  margin-bottom: -$grid-padding;
}

.content-full-width {
  width: 100%;
  margin-top: $content-margin;
  margin-bottom: 0; // to prevent double margin from other contents
}

// Grid
.content {
  &-row {
    @include column-margin();
    @include gridle_row();
  }
  &-column {
    @include gridle_grid(6);
    @include gridle_state (small) {
      @include gridle_grid (12);
    }
  }
  &-column2 {
    @include gridle_grid(6);
    @include gridle_state (medium) {
      @include gridle_grid (12);
    }
    @include gridle_state (small) {
      @include gridle_grid (12);
    }
  }
}

.content,
.content-row {
  & > .content-row,
  & > .content {
    &:last-child {
      margin-bottom: 0; // prevent double bottom margin when nesting
    }
  }
}

a {
  text-decoration: none;

  color: $white;
  &:hover {
    text-decoration: none;
  }
}

// Text Content mit schmalerer breite
.content-text {
  max-width: $textblock-max-width;
}
.content-textblock {
  @extend .content-block;
  @extend .content-text;
}
.content-text-smaller {
  max-width: $textblock-smaller-max-width;
}
.content-textblock-smaller {
  @extend .content-block;
  @extend .content-text-smaller;
}

.content__subhead {
  display: block;
}

.menu-separator {
}