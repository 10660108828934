$logo-min-margin: 10px;

.logos {
  margin: 0 -$logo-min-margin;

  text-align: center;
  // to allow the justify to work
  &:after {
    display: inline-block;

    width: 100%;
    height: 0;

    content:"";
  }
  .logo {
    display: inline-block;

    height: 60px;
    margin: 0 $logo-min-margin;
  }
}