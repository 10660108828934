$base-font: 'Source Sans Pro', Helvetica;
$base-line-height: 1.5;

$headings-font: "varelaround-regular", "varelaround-regular", Helvetica;
$body-font-size: 16px;
$font-size-big: 20px;

body {
  font-family: $base-font;
  font-size: $body-font-size;
  font-weight: 300;
  line-height: $base-line-height;
  letter-spacing: 0.7px;

  color: $body-font-color;
}

h1, h2, h3, h4 {
  font-family: $headings-font;
  font-weight: 300;

  color: $brand-321;
  color: #333333;
}

h1 {
  text-align: center;
  letter-spacing: -0.5px;

  font-size: 25px;
  font-weight: 300;
  line-height: 42px;
}
.content-block > h1 {
  // top level headings
  max-width: $textblock-smaller-max-width;
  margin-right: auto;
  margin-left: auto;
}

h2 {
  text-align: left;

  font-size: 21px;
  font-weight: 300;
  line-height: 27px;
}

h5, b {
  font-size: $font-size-big;
  font-weight: 700;
  line-height: 1.56;
}

p {
  font-weight: 300;
  line-height: 1.57;
}

.blue-link {
  color: $brand-321;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}


.big-link {
  font-family: $headings-font;
  font-size: 26px;
  font-weight: 400;

  color: $brand-321;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.text-center {
  text-align: center;
}

// === Mobile ===
@include gridle_state(small) {
  h1 {
    font-size: 22px;
    line-height: 30px;
  }
}