.autoresize {
  // This class itself is not used
  &__container {
    overflow: hidden;
  }
  &--width,
  &--height {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }
  &--width {
    width: 100%;
    height: auto;
  }
  &--height {
    width: auto;
    height: 100%;
  }
}