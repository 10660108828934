$nav-menu-mobile-state: small medium !default;
$nav-menu-desktop-state: large xlarge !default;
$nav-margin: 35px !default;
$nav-margin-small: 25px !default;

.nav {
  position: fixed;
  z-index: 100;
  top: 0;

  width: 100%;

  @include gridle_state($nav-menu-mobile-state) {
    &:not(.nav--closed) {
      background-color: $white;
      &, a {
        color: $brand-321;
      }
      .nav__logo,
      .nav__menu-toggle {
        &__graphic {
          opacity: 1;
          &--inverted {
            opacity: 0;
          }
        }
      }
    }
  }
  &, a {
    transition: all .5s ease;

    color: $brand-321;
  }
  &__menu-toggle,
  &__logo {
    margin: $nav-margin;
    @media (max-width: $page-max-width-with-margin) {
      margin: $nav-margin-small;
    }
  }
  &__menu {
    margin: $nav-margin - $menu-padding;
    @media (max-width: $page-max-width-with-margin) {
      margin: $nav-margin-small - $menu-padding;
    }
  }
  &__menu-toggle {
    display: none;
    float: left;

    position: absolute;
    z-index: 10;

    padding: $menu-padding;

    cursor: pointer;

    @include gridle_state($nav-menu-mobile-state) {
      display: block;
    }
  }
  &__menu {
    float: left;
    &-outer {
      float: left;
    }

    @include gridle_state($nav-menu-mobile-state) {
      width: 100%;
      margin-top: 0; // no double top margin in mobile nav

      &-outer {
        clear: both;

        max-height: 500px;

        transition: max-height .5s ease;
      }
      // Mobile Menu normal
      &__item {
        display: block;
      }
      &__subitem {
        display: inline-block;
      }
    }
    // Desktop Menu normal
    &__item {
      max-width: 150px;

      transition: all .5s ease !important;

      opacity: 1;
      &__container {
        // to prevent the width animation
        min-width: 120px;
      }
    }
    &__subitem {
      @extend .nav__menu__item;
      &--left {
        float: left;
      }
      &--right {
        float: right;
      }
    }
    &__link {
      &,
      &:hover {
        text-decoration: none;
      }
    }
    &-seperator {
      @include gridle_state($nav-menu-mobile-state) {
        // Rotate on mobile
        transform: rotateZ(90deg) translateY(-8px);
        display: inline-block;
      }
    }
  }
  &--closed {
    .nav__menu-toggle {
      display: block;
    }
    .nav__menu {
      visibility: hidden;

      @include gridle_state($nav-menu-mobile-state) {
        &-outer {
          // Mobile Menu open
          max-height: 0;
        }
      }
      // Desktop Menu open
      &__item {
        max-width: 25px;
        padding: $menu-padding 0;

        opacity: 0;
      }
    }
  }
  &__logo {
    float: right;

    position: relative;

    max-width: 50%;
  }
  &__logo,
  &__menu-toggle {
    &__graphic {
      position: absolute;
      &,
      &--inverted {
        display: block;

        max-width: 100%;

        transition: opacity .5s ease;
      }
      &--inverted {
        opacity: 0;
      }
    }
  }
  &--inverted {
    &, a {
      color: $white;
    }
    .nav__logo,
    .nav__menu-toggle {
      &__graphic {
        opacity: 0;
        &--inverted {
          opacity: 1;
        }
      }
    }
  }
  &--scrolled {
    background-color: $white;
   
    background-color: rgba(255,255,255,0.85);

    
    &, a {
      color: $brand-321;

    }
    .nav__logo,
    .nav__menu-toggle {
      &__graphic {
        opacity: 1;
        &--inverted {
          opacity: 0;
        }
      }
    }
  }
}

.visible-nav-mobile {
  display: none !important;
}

@include gridle_state($nav-menu-mobile-state) {
  .visible-nav-mobile {
    display: block !important;
  }
  .hidden-nav-mobile {
    display: none !important;
  }
}


.visible-nav-desktop {
  display: none !important;
}

@include gridle_state($nav-menu-desktop-state) {
  .visible-nav-desktop {
    display: block !important;
  }
  .hidden-nav-desktop {
    display: none !important;
  }
}
