button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  padding: .25em .5em .15em;

  transition: color .25s linear, background-color .25s linear;

  color: $brand-321;
  border: 2px solid;
  border-color: $brand-321;
  background: transparent;
}

button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover,
button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
button:active,
input[type="button"]:active,
input[type="reset"]:active,
input[type="submit"]:active {
  color: $white;
  background-color: $brand-321;
}