@mixin teaser-margin($direction) {
  #{$direction}: $page-margin;
  @media (max-width: $page-max-width-with-margin) {
    #{$direction}: $page-margin-sm;
  }
}

// from http://stackoverflow.com/questions/34762009/pulsing-heart-css-animation
$heartbeat-time-factor: 8; // by which factor to stretch the time around the heartbeat animation
$heartbeat-base-duration: 1; // base time (this modifies the speed of the heartbeat as well)
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  #{20%/$heartbeat-time-factor} {
    transform: scale(1.25);
  }
  #{40%/$heartbeat-time-factor} {
    transform: scale(1);
  }
  #{60%/$heartbeat-time-factor} {
    transform: scale(1.25);
  }
  #{80%/$heartbeat-time-factor} {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.teaser {
  position: relative;
  a {
    // Highlight teaser links
    font-weight: 600;
  }
  &__slide {
    position: relative;

    height: 100vh;
    max-height: 75vw;
    min-height: 400px;

    @include gridle_state(small) {
      max-height: none;
    }
  }
  &__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-size: cover;
    background-position: center center;
    background-color: $teaser-bg-color;
  }
  &__image,
  &__subimage,
  &__video {
    display: block;

    width: 100%;
  }
  &__headwrap {
    width: 70%;
    @include centerer(true, true);
    @include gridle_state(small) {
      margin-top: 15px; // fake centering
    }
  }
  &__heading,
  &__subheading {
    margin: 0 auto;
    text-align: center;
    font-weight: 300;
    letter-spacing: 0.5px;
    line-height: 1.3;
  }
  &__heading {
    color: $white;

    @include gridle_state(medium large xlarge) {
      font-size: 40px;
    }
  }
  &__scrolldown {
    bottom: 5%;

    cursor: pointer;

    @include centerer(true, false);

    @include gridle_state(small) {
      display: none;
    }
  }
  .slick-dots {
    position: absolute;

    margin: 0;
    padding: 0;

    list-style-type: none;

    @include teaser-margin(right);
    @include teaser-margin(bottom);
    & > li {
      float: left;

      margin-left:8px;

      transition: color .25s linear;

      font-weight: 400;
      font-size: 20px;

      color: $white;

      button {
        border: none;
        background: $brand-321;
        border-radius: 100px;
        width: 35px;
        height: 35px;
        line-height: 1;
        color: $white;
        padding-bottom: 5px;
        transition: color .5s ease, background .5s ease;

        &:focus {
          outline: none;
        }

        @include gridle_state(small) {
          width: 10px;
          height: 10px;
          font-size: 0;
        }
      }

      &.slick-active {
        button {
          color: $brand-321;
          background: $white;
        }
      }
    }
  }
  &--simple {
    height: 35vh;
  }
  // // Um badges innerhalb des Teasers zu benutzen
  // .slick-list.draggable {
  //   overflow: visible !important;
  //   overflow-y: visible;
  //   overflow-x: hidden;
  // }
}

// === Reference ===
.teaser__reference {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 64%,rgba(0,0,0,0.3) 100%);
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: ' ';
    transition: all .5s ease;

    background-color: rgba($brand-321, .8);
  }
  &__inner {
    position: relative;
    top: 0;

    height: 100%;

    transition: opacity .5s ease .25s, top .5s ease-out .15s;

    opacity: 1;
  }
  &__inner-scroll {
    @include gridle_state(small) {
      position: absolute;
      top: 60px + $grid-padding;
      bottom: 60px + $grid-padding;
      left: $grid-padding;
      right: $grid-padding;
      overflow: scroll;
    }
  }
  &__icon {
    float: left;

    position: relative;

    width: 40px;
    height: $base-line-height * 1em;
    margin-right: 10px;
    img {
      position: absolute;
      top: 50%;

      transform: translateY(-50%);
    }
    &--open,
    &--closed {
      transition: opacity .5s ease, width .5s ease, left .5s ease;
    }
    &--open {
      left: 0;

      width: 100%;

      opacity: 1;
    }
    &--closed {
      left: 50%;

      width: 0;

      opacity: 0;
    }
  }
  &__brand {
    position: absolute;

    cursor: pointer;

    font-size: 20px;

    color: $white;

    @include teaser-margin(left);
    @include teaser-margin(bottom);
  }
  &__heading {
    margin-bottom: $grid-padding;

    background: $white;
    padding: 20px;

    text-align: left;

    color: $brand-321;

    @include teaser-margin(left);

    @include gridle_state(medium large xlarge) {
      position: absolute;
      top: 40%;
      max-width: 30%;
      margin: 0;

      letter-spacing: -2px;
      font-weight: 400;
      line-height: 1.1;
    }
    @include gridle_state(medium) {
      font-size: 30px;
    }
    @include gridle_state(large xlarge) {
      max-width: 40%;
    }
    @include gridle_state(large) {
      font-size: 40px;
    }
    @include gridle_state(xlarge) {
      font-size: 50px;
    }
  }
  &__content {
    color: $white;

    @include teaser-margin(right);

    @include gridle_state(medium large xlarge) {
      position: absolute;
      top: 40%;

      width: 60%;
    }
    @include gridle_state(large xlarge) {
      width: 45%;
    }
  }
  &__website {
  }
  &__tags {
  }
  &__tag {
  }
  &--closed {
    .teaser__reference__inner {
      top: 25px;

      transition: all .25s ease;

      opacity: 0;
    }
    &::before {
      top: 50px;

      opacity: 0;
    }
    .teaser__reference__content,
    .teaser__reference__website,
    .teaser__reference__tag {
      color: $brand-321;
    }
    .teaser__reference__icon {
      animation: heartbeat ($heartbeat-base-duration * $heartbeat-time-factor * 1s) infinite 3s;
      &--open {
        left: 50%;

        width: 0;

        opacity: 0;
      }
      &--closed {
        left: 0;

        width: 100%;

        opacity: 1;
      }
    }
  }
}

// Fix for iOS bottom bar...
.ios .teaser__slide {
  height: 90vh;
}
