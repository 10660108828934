$tile-aspect-ratio: 230 / 367; // height / width

.tiles {
  @include column-anti-margin(); // to absorb the bottom padding from the tiles
  &__filters {
    margin-bottom: $content-margin;

    text-align: center;
  }
  &__filter-dropdown,
  &__filter, {
    margin: 0 .5em .5em;
    padding: .75em 1.5em;

    transition: all .5s ease;

    font-size: 18px;

    color: $brand-321;
    border: 1px solid $brand-321;
    background-color: transparent;
    &--active,
    &:hover, &:active, &:focus {
      color: $white;
      background-color: $brand-321;
    }
    &-button {
      @extend .hidden-small;
    }
    &-dropdown {
      width: 100%;
      margin: 0;

      @extend .visible-small;
    }
  }
  &__tile-container {
    @include gridle_row();
  }
  &__tile {
    @include gridle_grid(4);
    @include column-margin();
    &__outer {
      // to guarantee the correct height for images
      position: relative;

      padding-bottom: $tile-aspect-ratio * 100%;

      border: 1px solid $brand-321;
    }
    &__inner {
      position: absolute;

      width: 100%;
      height: 100%;
      &__bg {
        display: block;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        content: ' ';
        transition: opacity .5s ease;

        opacity: 0;
        background-color: $brand-321;
      }
    }
  }
  &__image {
    position: absolute;
    top: 0;

    width: 100%;
  }
  &__tags {
    position: absolute;
    z-index: 5;
    top: 75%;

    width: 100%;

    transition: opacity .5s ease;
    text-align: center;

    opacity: 0;
  }
  &__tile:hover {
    .tiles__tile__inner__bg {
      opacity: .8;
    }
    .tiles__tags {
      opacity: 1;
    }
  }
}

// Responsive
.tiles {
  @include gridle_state(small) {
    &__tile {
      @include gridle_grid(6);
    }
  }
}