// Mixins & Variables
@import '_variables';
@import '_mixins';

// Normalize Styles
@import 'node_modules/normalize.css/normalize';

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

// Import Packages
@import '_grid';
@import 'node_modules/slick-carousel/slick/slick.scss';

// Import PageStyles
@import '_fonts';
@import '_iconfont';
@import '_layout';
@import '_typography';
@import '_general';
@import '_buttons';
@import '_input';
@import '_responsive';

// Import Page Specific Styles
@import 'pages/_contact';
@import 'pages/_alexa';

// Import Modules
@import '../_modules/menu/menu';
@import '../_modules/nav/nav';
@import '../_modules/footer/footer';
@import '../_modules/teaser/teaser';
@import '../_modules/logos/logos';
@import '../_modules/badge/badge';
@import '../_modules/autoresize/autoresize';
@import '../_modules/tiles/tiles';
@import '../_modules/video/video';
@import '../_modules/people/people';
@import '../_modules/parallax/parallax';
@import '../_modules/alexaslider/alexaslider';
