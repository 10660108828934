.alexa {

	&-bubbles {
		width:120% !important;

		&-svg {
			width:100%;

			@include gridle_state (xlarge) {
				height:30em !important;
			}
		}

		@include gridle_state (small) {
			width:170% !important;
		}
		@include gridle_state (xlarge) {
			width:100% !important;
		}
	}

	&-firstRow {
		margin-top: -60px; 
		z-index:50;

		&-txt {
			margin-top: 25px;
		}
	}

	&-heading {
		text-align: left;
	  	letter-spacing: -0.5px;

		font-size: 40px;
		font-weight: 300;
		line-height: 50px;

		color: $brand-321;

		margin-top: 120px;
		margin-bottom: 70px;
	}
	&-copy {
		font-size: 22px;
		font-weight: 500;

		
	}
	&-white {
		color: #fff;
	}
	&-grey {
		color: $grey2 !important;
	}
	&-green {
		color: $brand-321 !important;
	}

	&-secondRow {
		background-image: url(../images/alexa/alexa_img1_bg.jpg); 
		background-position: center; 
		background-repeat:no-repeat; 
		background-size: cover;

		@include gridle_state (small) {
			background-image: none; 
		}
	}

	&-swiper {
		background-color: $brand-321;
		min-height:550px; 

		margin-top: 0px;
		padding-bottom:50px;

		&-img {
			float: right;
		}

		&-nav {
			margin: 0px auto;
			margin-top: -45px;
			text-align: center;

		}
		&-dot {
			height: 90px;
			width: 90px;
			background-color: #fff;
			border-radius: 50%;
			display: inline-block;
			box-shadow: 0px 8px 15px rgba(0, 0, 0, .1);
			cursor: pointer;

			&-heading {
				text-align: center;
				font-size: 35px;
				font-weight: 500;
				line-height:56px;
				color: $grey2;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}

			&-label {
				margin-top: -25px;
				font-size:13px;
				font-weight: 600;
				color: $grey2;
			}

			&.active {
				.alexa-swiper-dot-label, .alexa-swiper-dot-heading {
					color: $brand-321;
				}
			}
		}
	}

	&-faq {

		padding: 30px;

		background-image: url(../images/alexa/alexa_plus.svg); 
		background-position: 97% 50%; 
		background-repeat:no-repeat; 

		font-weight: 500;


		&-grey {
			background-color: #f0f0f0;
		}
		&-inner {
			width:85%;
		}

		@include gridle_state (small) {
			background-position: 94% 25%; 
		}

	}

	&-contact {
		text-align: center;
		font-size: 30px;
		font-weight: 500;
		line-height:56px;
		color: $brand-321;
	}

	&-contentsmall {
		width:70%;

		@include gridle_state (medium) {
			width:90%;
		}
		@include gridle_state (small) {
			width:100%;
		}
	}
  
	&-features {
		&-container {
			min-height: 830px;
			background-image: url('../images/alexa/echo_bg.jpg');
			background-repeat: no-repeat;
			background-size: auto;
			background-position: bottom center;

			padding-top:150px;

			@include gridle_state (medium) {
				padding-top:50px;
				background-image: none;
			}
			@include gridle_state (small) {
				padding-top:15px;
				background-image: none;
			}

		}

		&-box {
			padding: 10px;
			min-width: 190px;
			
			.inner{
				padding: 22px;
				min-height: 285px;
				background-color: rgba(230, 230, 230, 0.9);
				border-radius: 20px;

				@include gridle_state (medium) {
					min-height: 100px;
				}
				@include gridle_state (small) {
					min-height: 100px;
				}
			}

			.des{
				padding-left: 85px;
				padding-right: 10px;
				@include gridle_state (small) {
					padding-left: 20px;
				}
			}
		}

		&-box:last-child {
			margin-bottom: 50px;
		}
	}

	&-start-heading {
		text-align: left;

		color: $brand-321;

		margin-top: 70px;
		margin-bottom: 25px;

		@include gridle_state (small) {
			text-align:center;
		}
	}

	&-start-column-1 {
		width: 45%;

		margin-bottom: 50px;

		@include gridle_state (small) {
			width: 100%;
			text-align:center;
		}
	}
	&-start-column-2 {
		width: 55%;

		&-img {
			margin-top:70px;
		}

		@include gridle_state (small) {
			width: 100%;

			&-img {
				margin-top:0px;
				margin-bottom:50px;
			}	
			
		}
	}

	&-start-content {
		max-width: 900px;
		@include gridle_state (small) {
			width: 100%;
			text-align:center;
		}
	}

	&-tradeNote {
		display:inline-block;
		font-size:13px; 
		color:#fff;
		margin-top:20px;
		margin-bottom:30px;

		@include gridle_state (small) {
			color: #808080 !important;
			font-size:12px; 
			
			
		}

		
	}

}