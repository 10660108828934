$page-margin: 60px;
$page-margin-sm : $page-margin / 2;
$content-margin: 50px;
$content-margin-sm : $content-margin / 2;
$grid-padding: $content-margin / 2;

$white: #FFF;
$black: #000;
$grey: #D0D0D0;
$grey2: #bbb;
$brand-321: #037E93;

$teaser-bg-color: $grey;
$body-font-color: #666;

$teaser-bg-color: $white;


$page-max-width: 1140px;
$textblock-max-width: 960px;
$textblock-smaller-max-width: 800px;
$page-max-width-with-margin: $page-max-width + ($content-margin-sm * 2);
$br-fix-max-width: $page-max-width;