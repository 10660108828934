$people-aspect-ratio: 1 / 1; // height / width
$people-overlay-color: rgba($brand-321, .7);
$people-text-color: $white;

// The whole module / wrapper
.people {
  @include gridle_row();
}

// Tile for one single person
.person {
  @include gridle_grid(4);
  @include column_margin();
  &__outer {
    position: relative;

    padding-bottom: 100% * $people-aspect-ratio;
  }
  &__inner {
    display: flex;
    flex-direction: column;

    position: absolute;
    z-index: 1;
    top: 0;

    width: 100%;
    height: 100%;
    padding: 5%;

    transition: opacity .5s ease;

    opacity: 0;
    background-color: $people-overlay-color;

    align-items: center;
    justify-content: flex-end;
  }
  &__image {
    position: absolute;
    top: 0;

    width: 100%;
  }
  &__name,
  &__description {
    margin: 0;

    color: $people-text-color;
  }
  &:hover {
    .person__inner {
      opacity: 1;
    }
  }
}