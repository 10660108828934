$menu-padding: 5px !default;

.menu {
  &__item {
    display: inline-block;

    padding: $menu-padding;
  }
  @media (max-width: $br-fix-max-width) {
    &-br-fix {
      display: block;

      height: 0;

      opacity: 0;
    }
  }
}