.visible-small {
  display: none !important;
}

@include gridle_state(small) {
  .visible-small {
    display: block !important;
  }
  .hidden-small {
    display: none !important;
  }
}

@include gridle_state(medium) {
  .visible-medium {
    display: block !important;
  }
  .hidden-medium {
    display: none !important;
  }
}

@include gridle_state(small) {
  .force-center-small {
    text-align: center !important;
    & > * {
      float: none !important;

      margin: 0 auto !important;
    }
  }
}

