.alexa-slider-container {
  height: 100%;

  .content {
    margin-bottom: 0;
  }

  .alexa-swiper-img {
    margin-top: 70px;
    margin-right: 80px;
  }

  .alexa-heading {
    margin-top: 70px;
    text-transform: uppercase;
  }
}